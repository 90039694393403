import {
  CustomLibary,
  DateTimePeriodDto,
  ExchangeResourceTypeEnum,
  // IApplicationSettingsService,
  IAppointmentManager,
  IHash,
  IOutlookOrganizerRepository,
  IPlatformService,
  OutlookAppointmentBaseInfoDto,
  OutlookAppointmentDto,
  OutlookAppointmentRecurrenceDto,
  OutlookRecipientDto,
  RecurringChangeTypeEnum,
  // // TokenIApplicationSettingsService,
  TokenIOutlookOrganizerRepository,
  TokenIPlatformService,
  UidGenerator,
  WebAddinPlatforms,
} from "booking-app-op";
import {
  OutlookAppointment,
  OutlookAppointmentLocker,
  OutlookAppointmentStartedFlag,
  OutlookAppointmentWatchingFlag,
  OutlookRecipient,
} from "../models";
// import { RecurringHelper } from "../models/RecurringHelper";
import { container } from "tsyringe";
import { RecurringHelper } from "../models/RecurringHelper";
import { AuthenticationService } from "../../authentication";
import axios from "axios";

export class OutlookAppointmentManager implements IAppointmentManager {
  private readonly _appointment: OutlookAppointment;
  // private _apptSettingService: IApplicationSettingsService;
  private _platformService: IPlatformService;
  private _running: boolean;
  private _startedFlag: OutlookAppointmentStartedFlag;
  private _watchingFlag: OutlookAppointmentWatchingFlag;
  private _locker: OutlookAppointmentLocker;
  private _lastAttendeeSynctime: number;

  OnChanged: CustomLibary.Event<OutlookAppointmentDto>;
  OnStarted: CustomLibary.Event<boolean>;
  OnDateTimeChanged: CustomLibary.Event<DateTimePeriodDto>;
  OnRecipientsChanged: CustomLibary.Event<OutlookRecipientDto[]>;
  OnRecurrenceChanged: CustomLibary.Event<OutlookAppointmentRecurrenceDto>;
  Organizer: OutlookRecipientDto | undefined;
  _olOrganizerRepository: IOutlookOrganizerRepository;

  private readonly _customUidKey = "CUSTOM_UID";
  private readonly _uidKey = "UID";
  private readonly _rcExtendedResourcesKey = "RC_ExtendedResources";
  private readonly _rcExtendedResourcesTimeStampKey = "RC_ExtendedResources_TimeStamp";

  public currentResources?: OutlookRecipientDto[];

  constructor() {
    this._olOrganizerRepository = container.resolve(TokenIOutlookOrganizerRepository);
    this._platformService = container.resolve(TokenIPlatformService);
    this._startedFlag = new OutlookAppointmentStartedFlag();
    this._watchingFlag = new OutlookAppointmentWatchingFlag();
    this._running = false;
    this.OnChanged = new CustomLibary.Event<OutlookAppointmentDto>();
    this.OnStarted = new CustomLibary.Event<boolean>();
    this.OnDateTimeChanged = new CustomLibary.Event<DateTimePeriodDto>();
    this.OnRecipientsChanged = new CustomLibary.Event<Array<OutlookRecipientDto>>();
    this.OnRecurrenceChanged = new CustomLibary.Event<OutlookAppointmentRecurrenceDto>();
    this._locker = new OutlookAppointmentLocker();
    this._appointment = new OutlookAppointment();
    this._getItemId();
    // this._apptSettingService = container.resolve(TokenIApplicationSettingsService);
    this._lastAttendeeSynctime = Date.now();
  }
  GetMessageUrl(): string {
    let resVersion = Office.MailboxEnums.RestVersion.v2_0;
    if (this._appointment.ItemId) {
      let strRestid = Office.context.mailbox.convertToRestId(this._appointment.ItemId, resVersion);
      let getMessageUrl = "https://graph.microsoft.com/v1.0/me/events/" + strRestid + "?$select=iCalUId,originalStart";
      return getMessageUrl;
    }
    return "";
  }

  GetMessageUrlCallback(): Promise<string> {
    let resVersion = Office.MailboxEnums.RestVersion.v2_0;
    return new Promise((resolve, reject) => {
      let item = Office?.context?.mailbox?.item;
      if (item && item.itemType === Office.MailboxEnums.ItemType.Appointment) {
        let appCompose = item as Office.AppointmentCompose;
        if (appCompose && Office.context.requirements.isSetSupported("Mailbox", "1.8")) {
          if (appCompose.seriesId && this._platformService.GetCurrentPlatform() === WebAddinPlatforms.OutlookForMac) {
            let strRestid = Office.context.mailbox.convertToRestId(appCompose.seriesId, resVersion);
            let getMessageUrl =
              "https://graph.microsoft.com/v1.0/me/calendar/events/" + strRestid + "?$select=iCalUId,originalStart";
            resolve(getMessageUrl);
          } else {
            console.log("result", "vo ne");
            appCompose.getItemIdAsync((result) => {
              if (result?.value) {
                let strRestid = Office.context.mailbox.convertToRestId(result.value, resVersion);
                let getMessageUrl =
                  "https://graph.microsoft.com/v1.0/me/calendar/events/" + strRestid + "?$select=iCalUId,originalStart";
                resolve(getMessageUrl);
              } else {
                reject("");
              }
            });
          }
        } else {
          reject("");
        }
      } else {
        reject("");
      }
    });
  }

  GetAppointment(): OutlookAppointmentDto {
    return this._appointment.Export();
  }

  public async GetOrganizer(): Promise<void> {
    const organizer = this._olOrganizerRepository.GetOrganizer();
    const realOrganizer = await this._olOrganizerRepository.GetRealOrganizerOfAppointment();
    this.Organizer = {
      Name: realOrganizer?.Name ?? organizer?.DisplayName ?? "",
      Email: realOrganizer?.Email ?? organizer?.Email ?? "",
    };
  }

  private _getItemId(): void {
    let item = Office?.context?.mailbox?.item;
    if (item && item.itemType === Office.MailboxEnums.ItemType.Appointment) {
      let appCompose = item as Office.AppointmentCompose;
      if (appCompose && Office.context.requirements.isSetSupported("Mailbox", "1.8")) {
        if (appCompose.seriesId && this._platformService.GetCurrentPlatform() === WebAddinPlatforms.OutlookForMac) {
          this._appointment.ItemId = appCompose.seriesId;
        } else {
          appCompose.getItemIdAsync((result) => {
            if (result.value) {
              this._appointment.ItemId = result.value;
            }
          });
        }
      }
    }
  }
  Start() {
    if (!this._running) {
      if (!Office.context.requirements.isSetSupported("Mailbox", "1.8")) {
        this._extendAppointment();
      }
      this._appointmentWatcher();
    }
  }
  UpdateResources(_resources: Array<OutlookRecipientDto>): void {
    //let isResourcesUpdated = this._appointment.UpdateResources(resources);
    //if (isResourcesUpdated) {
    let allRecipients = this._appointment.ExportRecipients();
    this.OnRecipientsChanged.Fire(allRecipients);
    this._notifyChange();
    // }
    this._startedFlag.Resources = true;
    this._startSuccess();
  }
  AddResources(resources: OutlookRecipientDto[], locations: string[]): boolean {
    let result = false;
    this._lastAttendeeSynctime = Date.now();
    const isPlatformMac = this._platformService.GetCurrentPlatform() === WebAddinPlatforms.OutlookForMac;
    try {
      if (isPlatformMac) {
        setTimeout(() => {
          this._addResources(resources);
        }, 3000);
      } else {
        this._addResources(resources);
      }
      if (!Office.context.requirements.isSetSupported("Mailbox", "1.8") || isPlatformMac) {
        this._setLocations(locations);
      }

      result = true;
    } catch (ex) {
      //console.log(ex);
      result = false;
    }
    this._lastAttendeeSynctime = Date.now();
    return result;
  }
  private _addResources(resources: OutlookRecipientDto[]) {
    let platform = this._platformService.GetCurrentPlatform();
    // use add async instead of set async to improve performance.
    if (!Office.context.requirements.isSetSupported("Mailbox", "1.8")) {
      let addedResources = this._appointment.AddRequiredAttendee(resources);
      this._addRequiredAttendeesToOutlook(addedResources);
    } else {
      let addedResources = this._appointment.AddRecipients(resources);
      this._addResourcesToOutlook(addedResources);
    }
  }

  RemoveResources(resourceEmails: string[], locations: string[]): boolean {
    let result = false;
    this._lastAttendeeSynctime = Date.now();
    try {
      this._removeResources(resourceEmails);
      this.DeleteLocations(locations);
      if (
        this._platformService.GetCurrentPlatform() === WebAddinPlatforms.OutlookForMac ||
        !Office.context.requirements.isSetSupported("Mailbox", "1.8")
      ) {
        this._setLocations(locations);
      }
      result = true;
    } catch (ex) {
      //console.log(ex);
      result = false;
    }
    this._lastAttendeeSynctime = Date.now();
    return result;
  }
  UpdateRecipients(recipients: OutlookRecipientDto[]): boolean {
    let result = false;
    this._lastAttendeeSynctime = Date.now();
    try {
      let isUpdated = this._appointment.UpdateRecipients(recipients);
      this._updateAttendeesToOutlook(this._appointment.RequiredRecipients, this._appointment.OptionalRecipients);
      if (isUpdated) {
        let resourceDtos = this._appointment.ExportRecipients();
        this.OnRecipientsChanged.Fire(resourceDtos);
      }
      result = true;
    } catch (ex) {
      //console.log(ex);
      result = false;
    }
    this._lastAttendeeSynctime = Date.now();
    return result;
  }
  UpdateAppointmentBaseInfo(apptDto: OutlookAppointmentBaseInfoDto): boolean {
    let result = false;
    try {
      this._appointment.Guid = apptDto.Guid;
      this._appointment.GlobalId = apptDto.GlobalId;
      this._appointment.CustomUid = apptDto.CustomUid ?? "";
      this._appointment.Uid = apptDto.Uid;
      this._appointment.OriginalStart = apptDto.OriginalStart;
      this._appointment.OriginalEnd = apptDto.OriginalEnd;
      this._appointment.HaveOrder = apptDto.HaveOrder;
      this._appointment.HaveNormalOrder = apptDto.HaveNormalOrder;
      this._appointment.IsReOpenAfterSent = apptDto.IsReopenAfterSent;

      // if (this._appointment.IsReOpenAfterSent) {
      //   this.clearAllCustomProperties();
      // }

      if (
        apptDto.CustomUid &&
        apptDto.CustomUid !== "" &&
        !Office.context.requirements.isSetSupported("Mailbox", "1.8")
      ) {
        let properties: IHash<string> = {};
        properties[this._customUidKey] = this._appointment.CustomUid;
        this._setCustomProperty(properties);
      }

      if (this._appointment.IsReOpenAfterSent) {
        const item = Office.context.mailbox.item;
        if (item) {
          item.sessionData.setAsync("REOPEN_RC", "1");
        }
      }

      result = true;
    } catch (ex) {
      //console.log(ex);
    }
    return result;
  }

  public GetRecipients(): OutlookRecipientDto[] {
    let recipients = this._appointment.Recipients;
    let result: OutlookRecipientDto[] = [];
    if (recipients && recipients.length > 0) {
      result = this._appointment.ExportRecipients();
    }
    return result;
  }
  private _removeResources(resourceEmails: string[]) {
    this._appointment.RemoveRecipients(resourceEmails);
    this._updateRecipientsToOutlook(
      this._appointment.RequiredRecipients,
      this._appointment.OptionalRecipients,
      this._appointment.Resources,
      resourceEmails
    );
  }

  private _updateAttendeesToOutlook(requiredRecipients: OutlookRecipient[], optionalRecipients: OutlookRecipient[]) {
    let organizer = this._olOrganizerRepository.GetOrganizer();
    requiredRecipients = requiredRecipients.filter((rep) => rep.Email.toLowerCase() != organizer?.Email.toLowerCase());
    this._setRequiredAttendeesToOutlook(requiredRecipients);
    this._setOptionalAttendeesToOutlook(optionalRecipients);
  }

  private _updateRecipientsToOutlook(
    requiredRecipients: OutlookRecipient[],
    optionalRecipients: OutlookRecipient[],
    resources: OutlookRecipient[],
    resourceEmails: string[]
  ) {
    let requireRecipientsToRemove: OutlookRecipient[] = [];
    let organizer = this._olOrganizerRepository.GetOrganizer();
    if (requiredRecipients && requiredRecipients.length > 0) {
      requireRecipientsToRemove = requireRecipientsToRemove
        .concat(requiredRecipients)
        .filter((rep) => rep.Email.toLowerCase() != organizer?.Email.toLowerCase());
    }
    if (resources && resources.length > 0) {
      // the equipments will be set to required attendees field instead of enhanced location.
      let equipments = resources.filter((rs) => rs.ResourceType === ExchangeResourceTypeEnum.Equipment);
      if (equipments && equipments.length > 0) {
        requireRecipientsToRemove = requireRecipientsToRemove.concat(equipments);
        resources = resources.filter((rs) => rs.ResourceType != ExchangeResourceTypeEnum.Equipment);
      }
    }
    this._updateAttendeesToOutlook(requireRecipientsToRemove, optionalRecipients);
    let platform = this._platformService.GetCurrentPlatform();
    // use add async instead of set async to improve performance.
    if (platform === WebAddinPlatforms.OutlookForMac && !Office.context.requirements.isSetSupported("Mailbox", "1.8")) {
      //console.log("Outlook for Mac with requirement set < 1.8 does not support set to resources/enhancedlocation field");
    } else {
      //console.log("process set resources to outlook: ");
      //console.log(resources);
      let currentRecipients = resources;
      if (!Office.context.requirements.isSetSupported("Mailbox", "1.8")) {
        currentRecipients.push(...requireRecipientsToRemove);
        currentRecipients.push(...optionalRecipients);
      }
      this._setRcResourcesToOutlook(currentRecipients, resourceEmails);
    }
  }
  private _setRequiredAttendeesToOutlook(currentResources: Array<OutlookRecipient>) {
    try {
      var newRecipientsMap: IHash<Office.EmailAddressDetails> = {};
      let olRecipients: Array<Office.EmailAddressDetails> = [];
      currentResources.forEach((rs) => {
        let olRecipient: Office.EmailAddressDetails = {
          displayName: rs.Name,
          emailAddress: rs.Email,
          appointmentResponse: Office.MailboxEnums.ResponseType.None,
          recipientType: Office.MailboxEnums.RecipientType.Other,
        };
        olRecipients.push(olRecipient);
        newRecipientsMap[olRecipient.emailAddress] = olRecipient;
      });
      if (Office.context && Office.context.mailbox) {
        let item = Office.context.mailbox.item;
        if (item && item.itemType === Office.MailboxEnums.ItemType.Appointment) {
          let appCompose = item as Office.AppointmentCompose;
          if (appCompose) {
            let option: Office.AsyncContextOptions = {
              asyncContext: {},
            };
            appCompose.requiredAttendees.getAsync(
              option,
              (asyncResult: Office.AsyncResult<Office.EmailAddressDetails[]>) => {
                if (Office.AsyncResultStatus.Succeeded === asyncResult.status) {
                  var recipientsToSet: Array<Office.EmailAddressDetails> = [];
                  var oldRecipientsMap: IHash<Office.EmailAddressDetails> = {};
                  if (asyncResult.value && asyncResult.value.length > 0) {
                    asyncResult.value.forEach((oldRp) => {
                      if (newRecipientsMap[oldRp.emailAddress]) {
                        recipientsToSet.push(oldRp);
                        oldRecipientsMap[oldRp.emailAddress] = oldRp;
                      }
                    });
                  }
                  if (olRecipients && olRecipients.length > 0) {
                    olRecipients.forEach((newRp) => {
                      if (!oldRecipientsMap[newRp.emailAddress]) {
                        recipientsToSet.push(newRp);
                      }
                    });
                  }
                  this._lastAttendeeSynctime = Date.now();
                  appCompose.requiredAttendees.setAsync(recipientsToSet, () => {
                    this._lastAttendeeSynctime = Date.now();
                  });
                }
              }
            );
          }
        }
      }
    } catch (ex) {
      //console.log(ex);
      this._lastAttendeeSynctime = Date.now();
    }
  }
  private _addRequiredAttendeesToOutlook(newRecipients: Array<OutlookRecipient>) {
    try {
      if (newRecipients != null && newRecipients.length > 0) {
        let olRecipients = newRecipients.map((rs) => {
          let olRecipient: Office.EmailUser = {
            displayName: rs.Name,
            emailAddress: rs.Email,
          };
          return olRecipient;
        });
        if (Office.context && Office.context.mailbox) {
          let item = Office.context.mailbox.item;
          if (item && item.itemType === Office.MailboxEnums.ItemType.Appointment) {
            let appCompose = item as Office.AppointmentCompose;
            if (appCompose) {
              this._lastAttendeeSynctime = Date.now();
              appCompose.requiredAttendees.addAsync(olRecipients, () => {
                this._lastAttendeeSynctime = Date.now();
              });
            }
          }
        }
      }
    } catch (ex) {
      //console.log(ex);
      this._lastAttendeeSynctime = Date.now();
    }
  }
  private _addResourcesToOutlook(newResources: Array<OutlookRecipient>) {
    try {
      if (newResources != null && newResources.length > 0) {
        let olResources = newResources.map((rs) => {
          let olRecipient: Office.EmailUser = {
            displayName: rs.Name,
            emailAddress: rs.Email,
          };
          return olRecipient;
        });
        if (Office.context && Office.context.mailbox) {
          let item = Office.context.mailbox.item;
          if (item && item.itemType === Office.MailboxEnums.ItemType.Appointment) {
            let appCompose = item as Office.AppointmentCompose;
            if (appCompose) {
              this._lastAttendeeSynctime = Date.now();
              if (Office.context.requirements.isSetSupported("Mailbox", "1.8")) {
                var newResourcesEquipment = newResources.filter((rs) => {
                  return rs.ResourceType === ExchangeResourceTypeEnum.Equipment;
                });
                if (newResourcesEquipment != null && newResourcesEquipment.length > 0) {
                  olResources = newResourcesEquipment.map((rs) => {
                    let olRecipient: Office.EmailUser = {
                      displayName: rs.Name,
                      emailAddress: rs.Email,
                    };
                    return olRecipient;
                  });
                  appCompose.requiredAttendees.addAsync(olResources, () => {
                    this._lastAttendeeSynctime = Date.now();
                  });
                  this.SetLocations2(olResources.map((item) => item.displayName));
                }

                var newResourcesRoom = newResources.filter((rs) => {
                  return (
                    rs.ResourceType === ExchangeResourceTypeEnum.Room ||
                    rs.ResourceType == ExchangeResourceTypeEnum.Workspace
                  );
                });

                if (newResourcesRoom != null && newResourcesRoom.length > 0) {
                  let olResourcesLocation = newResourcesRoom.map((rs) => {
                    let olRecipient: Office.LocationIdentifier = {
                      id: rs.Email,
                      type: Office.MailboxEnums.LocationType.Room,
                    };
                    return olRecipient;
                  });
                  appCompose.enhancedLocation.addAsync(
                    olResourcesLocation,
                    (_asyncResult: Office.AsyncResult<void>) => {
                      this._lastAttendeeSynctime = Date.now();
                    }
                  );
                }
              } else {
                (appCompose as any).rcExtendedResources.addAsync(olResources, () => {
                  this._lastAttendeeSynctime = Date.now();
                });
                this._setCustomRcExtendedResources(olResources, true);
              }
            }
          }
        }
      }
    } catch (ex) {
      //console.log(ex);
      this._lastAttendeeSynctime = Date.now();
    }
  }
  private _setOptionalAttendeesToOutlook(currentResources: Array<OutlookRecipient>) {
    try {
      let emailSet = new Set();
      let olRecipients = new Array<Office.EmailUser>();

      currentResources.forEach((rs) => {
        if (!emailSet.has(rs.Email)) {
          emailSet.add(rs.Email);
          let olRecipient = {
            displayName: rs.Name,
            emailAddress: rs.Email,
          };
          olRecipients.push(olRecipient);
        } else {
          console.log("Duplicate found: ", rs.Email);
          // Handle the duplicate as needed
        }
      });

      if (Office.context && Office.context.mailbox) {
        let item = Office.context.mailbox.item;
        if (item && item.itemType === Office.MailboxEnums.ItemType.Appointment) {
          let appCompose = item as Office.AppointmentCompose;
          //appCompose.getItemIdAsync
          if (appCompose) {
            this._lastAttendeeSynctime = Date.now();
            console.log("set optional ", olRecipients);
            appCompose.optionalAttendees.setAsync(olRecipients, () => {
              this._lastAttendeeSynctime = Date.now();
            });
          }
        }
      }
    } catch (ex) {
      console.log(ex);
      this._lastAttendeeSynctime = Date.now();
    }
  }
  private _setRcResourcesToOutlook(currentResources: Array<OutlookRecipient>, resourceEmails: string[]) {
    try {
      let emailSet = new Set();
      let olRecipients = new Array<Office.EmailUser>();

      currentResources.forEach((rs) => {
        if (!emailSet.has(rs.Email)) {
          emailSet.add(rs.Email);
          let olRecipient = {
            displayName: rs.Name,
            emailAddress: rs.Email,
          };
          olRecipients.push(olRecipient);
        } else {
          console.log("Duplicate found: ", rs.Email);
          // Handle the duplicate as needed
        }
      });
      if (Office.context && Office.context.mailbox) {
        let item = Office.context.mailbox.item;
        if (item && item.itemType === Office.MailboxEnums.ItemType.Appointment) {
          let appCompose = item as Office.AppointmentCompose;
          if (appCompose) {
            this._lastAttendeeSynctime = Date.now();
            if (Office.context.requirements.isSetSupported("Mailbox", "1.8")) {
              let locationIdentifiers: Office.LocationIdentifier[] = resourceEmails.map(
                (r) =>
                  ({
                    id: r,
                    type: Office.MailboxEnums.LocationType.Room,
                  } as Office.LocationIdentifier)
              );
              appCompose.enhancedLocation.removeAsync(locationIdentifiers);
            } else {
              (appCompose as any).rcExtendedResources.setAsync(olRecipients, () => {
                this._lastAttendeeSynctime = Date.now();
              });
              //appCompose.requiredAttendees.setAsync(olRecipients, () => {
              //    this._lastAttendeeSynctime = Date.now();
              //});
              this._setCustomRcExtendedResources(olRecipients, false);
            }
          }
        }
      }
    } catch (ex) {
      console.log(ex);
      this._lastAttendeeSynctime = Date.now();
    }
  }
  public SetLocations(locations: string[]): boolean {
    return this._setLocations(locations);
  }

  public SetLocations2(locations: string[]): void {
    try {
      let locationsString = "";
      if (locations != null && locations.length > 0) {
        locationsString = locations.join(";");
      }
      if (Office.context && Office.context.mailbox) {
        let item = Office.context.mailbox.item;
        if (item && item.itemType === Office.MailboxEnums.ItemType.Appointment) {
          let appCompose = item as Office.AppointmentCompose;
          if (appCompose) {
            appCompose.location.getAsync(function (result) {
              if (result.status === Office.AsyncResultStatus.Succeeded) {
                const oldLocation: string[] = result.value.split(";");
                const newLocation = new Set([...oldLocation, ...locationsString.split(";")]);
                appCompose.location.setAsync((newLocation as any).filter(item => !!item).join(";"));
              } else {
                appCompose.location.setAsync(locationsString);
              }
            });
          }
        }
      }
    } catch (ex) {
      //console.log(ex);
    }
  }

  public ReplaceLocation(locations: string[]): void {
    try {
      if (Office.context && Office.context.mailbox) {
        let item = Office.context.mailbox.item;
        if (item && item.itemType === Office.MailboxEnums.ItemType.Appointment) {
          let appCompose = item as Office.AppointmentCompose;
          if (appCompose) {
            appCompose.location.getAsync(function (result) {
              if (result.status === Office.AsyncResultStatus.Succeeded) {
                appCompose.location.setAsync(locations.join(";"));
              }
            });
          }
        }
      }
    } catch (ex) {
      //console.log(ex);
    }
  }

  public DeleteLocations(locations: string[]): void {
    try {
      if (Office.context && Office.context.mailbox) {
        let item = Office.context.mailbox.item;
        if (item && item.itemType === Office.MailboxEnums.ItemType.Appointment) {
          let appCompose = item as Office.AppointmentCompose;
          if (appCompose) {
            appCompose.location.getAsync(function (result) {
              if (result.status === Office.AsyncResultStatus.Succeeded) {
                const oldLocation: string[] = result.value.split(";");
                const newLocation = oldLocation.filter((item) => !locations.includes(item.trim()));
                appCompose.location.setAsync((newLocation as any).join(";"));
              }
            });
          }
        }
      }
    } catch (ex) {
      //console.log(ex);
    }
  }

  private _setLocations(locations: string[]) {
    let isUpdated = false;
    if (this._appointment.IsPlatformSupported) return isUpdated;
    try {
      let locationsString = "";
      if (locations != null && locations.length > 0) {
        locationsString = locations.join(";");
      }
      if (Office.context && Office.context.mailbox) {
        let item = Office.context.mailbox.item;
        if (item && item.itemType === Office.MailboxEnums.ItemType.Appointment) {
          let appCompose = item as Office.AppointmentCompose;
          if (appCompose) {
            appCompose.location.setAsync(locationsString);
            isUpdated = true;
          }
        }
      }
    } catch (ex) {
      //console.log(ex);
    }
    return isUpdated;
  }
  private _startSuccess() {
    if (!this._running) {
      if (this._startedFlag.IsStarted) {
        this.OnStarted.Fire(this._running);
        this._running = true;
      }
    }
  }
  private _appointmentWatcher() {
    this._loadCustomProperties(null, this._loadCustomPropertiesCallback.bind(this));
    this._syncAppointmentInfo();
    this._subscribeApptEvent();
    if (
      !Office.context.requirements.isSetSupported("Mailbox", "1.8") ||
      WebAddinPlatforms.OutlookForMac === this._platformService.GetCurrentPlatform()
    ) {
      if (this._watchingFlag.EnableWatching) {
        setInterval(() => {
          this._processWatch();
        }, 3000);
      }
    }
  }
  private _syncAppointmentInfo() {
    let item = Office.context.mailbox.item;
    if (item) {
      let organizer = this._olOrganizerRepository.GetOrganizer();
      this._appointment.OrganizerEmail = this.Organizer?.Email;
      let watchTimeStamp: number = Date.now();
      //let allRecipients: OutlookRecipientDto[] = [];
      let option: Office.AsyncContextOptions = {
        asyncContext: {
          timeStamp: watchTimeStamp,
          isRecipientsUpdated: false,
        },
      };
      if (item.itemType === Office.MailboxEnums.ItemType.Appointment) {
        let appCompose = item as Office.AppointmentCompose;
        //Sync required attendees
        appCompose.requiredAttendees.getAsync(option, this._watchRequiredRecipient.bind(this));

        let asyncContext: GetDateTimeAsyncContext = {
          Done: (_done: boolean) => {
            try {
              this._appointment.SeriesId = appCompose.seriesId;
              if (appCompose && appCompose.recurrence) {
                this._loadMeetingRecurrenceInfo(false);
              } else {
                this._startedFlag.Recurrence = true;
                this._startSuccess();
              }
            } catch (ex) {
              this._startedFlag.Recurrence = true;
              this._startSuccess();
            }
          },
        };
        let optionsStart: Office.AsyncContextOptions = {
          asyncContext: asyncContext,
        };

        appCompose.start.getAsync(optionsStart, this._watchStartTime.bind(this));
        appCompose.subject.getAsync(this._watchSubject.bind(this));
      }
    }
  }
  private _processWatch() {
    let item = Office.context.mailbox.item;

    let organizer = this._olOrganizerRepository.GetOrganizer();
    this._appointment.OrganizerEmail = this.Organizer?.Email;
    let watchTimeStamp: number = Date.now();
    //let allRecipients: OutlookRecipientDto[] = [];
    let option: Office.AsyncContextOptions = {
      asyncContext: {
        timeStamp: watchTimeStamp,
        isRecipientsUpdated: false,
      },
    };
    if (item && item.itemType === Office.MailboxEnums.ItemType.Appointment) {
      let appCompose = item as Office.AppointmentCompose;
      //watch Start time & End time
      if (this._watchingFlag.EnableWatchRecipients) {
        appCompose.requiredAttendees.getAsync(option, this._watchRequiredRecipient.bind(this));
      }
      if (this._watchingFlag.EnableWatchTime) {
        appCompose.start.getAsync(this._watchStartTime.bind(this));
      }
      if (this._watchingFlag.EnableWatchSubject) {
        appCompose.subject.getAsync(this._watchSubject.bind(this));
      }
    }
  }

  private _subscribeApptEvent() {
    try {
      let item = Office.context.mailbox.item;
      if (item && item.itemType === Office.MailboxEnums.ItemType.Appointment) {
        let appCompose = item as Office.AppointmentCompose;
        this._appointment.SeriesId = appCompose.seriesId;
        if (appCompose && appCompose.recurrence) {
          if (Office.context.requirements.isSetSupported("Mailbox", "1.8")) {
            appCompose.addHandlerAsync(
              Office.EventType.AppointmentTimeChanged,
              this._appTimeChangeHandler.bind(this),
              this._subscribeApptTimeChangedCallback.bind(this)
            );
            if (WebAddinPlatforms.OutlookForMac !== this._platformService.GetCurrentPlatform()) {
              appCompose.addHandlerAsync(
                Office.EventType.EnhancedLocationsChanged,
                this._resourceEnhancedLocationsChangedHandler.bind(this),
                {},
                (_result: Office.AsyncResult<void>) => {
                  //console.log(result);
                }
              );
              appCompose.addHandlerAsync(
                Office.EventType.RecipientsChanged,
                () => {
                  let option: Office.AsyncContextOptions = {
                    asyncContext: {
                      isRecipientsUpdated: false,
                    },
                  };
                  appCompose.requiredAttendees.getAsync(option, this._watchRequiredRecipient.bind(this));
                },
                {},
                (_result: Office.AsyncResult<void>) => {
                  //console.log(result);
                }
              );
            }
          }
          //appCompose.addHandlerAsync(Office.EventType.RecipientsChanged, this._appRecipientsChangeHandler.bind(this), this._subscribeApptRecipientsChangedCallback.bind(this));
          //if (Office.context.requirements.isSetSupported('Mailbox', '1.8')) {
          //    appCompose.addHandlerAsync(Office.EventType.EnhancedLocationsChanged, this._watchEnhancedLocation.bind(this), this._subscribeEnhancedLocationsChangedCallback.bind(this));
          //}
          appCompose.addHandlerAsync(
            Office.EventType.RecurrenceChanged,
            this._recurringPatternChangeHandler.bind(this),
            {},
            this._recurringPatternChangeCallback.bind(this)
          );
        } else {
          this._startedFlag.Recurrence = true;
          this._startSuccess();
        }
        // get async from enhance locations
        if (
          this._appointment.IsPlatformSupported &&
          this._platformService.GetCurrentPlatform() !== WebAddinPlatforms.OutlookForMac
        ) {
          let olRecipient: Office.LocationIdentifier = {
            id: "...",
            type: Office.MailboxEnums.LocationType.Custom,
          };
          let olRecipientsLocation = [olRecipient];
          appCompose.enhancedLocation.addAsync(olRecipientsLocation, () => {
            this._lastAttendeeSynctime = Date.now();
            appCompose.enhancedLocation.removeAsync(olRecipientsLocation, () => {
              this._lastAttendeeSynctime = Date.now();
            });
          });
          //appCompose.enhancedLocation.getAsync(option, this._resourceEnhancedLocation.bind(this));
        }
      }
    } catch (ex) {
      this._startedFlag.Recurrence = true;
      this._startSuccess();
    }
  }

  private _resourceEnhancedLocationsChangedHandler(asyncResult: Office.EnhancedLocationsChangedEventArgs) {
    // update resources to appoiments
    try {
      if (asyncResult) {
        let rcResources: Array<Office.LocationDetails> = asyncResult.enhancedLocations;
        let olResources: OutlookRecipient[] = rcResources
          ? rcResources.map(
              (rs) =>
                new OutlookRecipient(
                  rs.emailAddress,
                  rs.displayName,
                  undefined,
                  rs.locationIdentifier.type === Office.MailboxEnums.LocationType.Room
                    ? ExchangeResourceTypeEnum.Room
                    : ExchangeResourceTypeEnum.Equipment
                )
            )
          : [];

        olResources = olResources.filter((rs) => rs.Email != "");

        let isResourcesUpdated = this._appointment.UpdateResources(olResources);

        if (isResourcesUpdated) {
          let allRecipients = this._appointment.ExportRecipients();
          this.OnRecipientsChanged.Fire(allRecipients);
          this._notifyChange();
        }
        this._startedFlag.Resources = true;
        this._startSuccess();
      }
    } catch (ex) {
      //console.log(ex);
    } finally {
    }
  }

  private _watchResourcesEnhancedLocation(asyncResult: Office.AsyncResult<Array<Office.EmailAddressDetails>>) {
    try {
      let timeStamp = asyncResult.asyncContext.timeStamp as number;
      if (timeStamp < this._lastAttendeeSynctime) return;
      if (asyncResult.status !== Office.AsyncResultStatus.Succeeded || asyncResult.value == null) {
        return;
      }
      let rcResources: Array<Office.EmailAddressDetails> = asyncResult.value;
      let olResources: OutlookRecipient[] = rcResources
        ? rcResources.map((rs) => new OutlookRecipient(rs.emailAddress, rs.displayName))
        : [];

      let isResourcesUpdated = this._appointment.UpdateResources(olResources);
      if (isResourcesUpdated) {
        asyncResult.asyncContext.isRecipientsUpdated = true;
      }

      if (asyncResult.asyncContext.isRecipientsUpdated) {
        let allRecipients = this._appointment.ExportRecipients();
        this.OnRecipientsChanged.Fire(allRecipients);
        this._notifyChange();
      }

      this._startedFlag.Resources = true;
      this._startSuccess();
    } catch (ex) {
      //console.log(ex);
    } finally {
    }
  }

  private _subscribeApptTimeChangedCallback(result: Office.AsyncResult<void>) {
    if (result.status === Office.AsyncResultStatus.Succeeded) {
      this._watchingFlag.EnableWatchTime = false;
    }
  }

  private _appTimeChangeHandler(_eventArgs: any) {
    let item = Office.context.mailbox.item;
    if (item && item.itemType === Office.MailboxEnums.ItemType.Appointment) {
      let appCompose = item as Office.AppointmentCompose;
      appCompose.start.getAsync(this._watchStartTime.bind(this));
    }
  }

  private _loadMeetingRecurrenceInfo(isChanged: boolean, eventArgs?: Office.RecurrenceChangedEventArgs) {
    let item = Office.context.mailbox.item;
    if (item && item.itemType === Office.MailboxEnums.ItemType.Appointment) {
      let appCompose = item as Office.AppointmentCompose;
      let option: Office.AsyncContextOptions = {
        asyncContext: {
          IsChanged: isChanged,
          Recurrence: eventArgs ? eventArgs.recurrence : null,
        },
      };
      appCompose.recurrence.getAsync(option, this._loadMeetingRecurrenceInfoCallback.bind(this));
    }
  }

  private _recurringPatternChangeCallback(_obj: any) {}

  private _recurringPatternChangeHandler(eventArgs: any) {
    // update Start Date And End Date
    let item = Office.context.mailbox.item;
    if (item && item.itemType === Office.MailboxEnums.ItemType.Appointment) {
      let appCompose = item as Office.AppointmentCompose;
      appCompose.start.getAsync(this._watchStartTime.bind(this));
      try {
        this._appointment.SeriesId = appCompose.seriesId;
        if (appCompose && appCompose.recurrence) {
          this._loadMeetingRecurrenceInfo(true, eventArgs);
        } else {
          this._startedFlag.Recurrence = true;
          this._startSuccess();
        }
      } catch (ex) {
        this._startedFlag.Recurrence = true;
        this._startSuccess();
      }
    }
  }

  private _loadMeetingRecurrenceInfoCallback(asyncResult: Office.AsyncResult<Office.Recurrence>) {
    let recurrenceInfo = asyncResult.value as Office.Recurrence;
    if (asyncResult.asyncContext.Recurrence && !recurrenceInfo) {
      recurrenceInfo = asyncResult.asyncContext.Recurrence as Office.Recurrence;
    }
    let isOldRecurringMaster = this._appointment.IsRecurringMaster;
    if (recurrenceInfo == null || (this._appointment.SeriesId != null && this._appointment.SeriesId != "")) {
      this._appointment.IsRecurringMaster = false;
      this._appointment.Occurrences = [];
      if (this._appointment.SeriesId) {
        this._appointment.IsOccurence = true;
      }
      this._notifyRecurrenceChanged(isOldRecurringMaster, asyncResult);
    } else {
      RecurringHelper.GetRecurringDates(recurrenceInfo, 50).then((occurrences: any) => {
        this._appointment.IsRecurringMaster = true;
        this._appointment.Occurrences = occurrences;

        this._appointment.RecurringAppDuration = recurrenceInfo.seriesTime.getDuration().toString();
        this._appointment.IsNoEndDate = recurrenceInfo.seriesTime.getEndDate() == null;
        this._notifyRecurrenceChanged(isOldRecurringMaster, asyncResult);
      });
    }
  }
  private _notifyRecurrenceChanged(isOldRecurringMaster: boolean, asyncResult: Office.AsyncResult<Office.Recurrence>) {
    if (asyncResult.asyncContext && asyncResult.asyncContext.IsChanged) {
      let copyAppt = this._appointment.Export();
      // case recurrence changed.
      this.OnRecurrenceChanged.Fire({
        IsRecurringMaster: copyAppt.IsRecurringMaster ?? false,
        Series: {
          StartTicks: copyAppt.StartTimeTicks,
          EndTicks: copyAppt.EndTimeTicks,
          Start: copyAppt.StartTime,
          End: copyAppt.EndTime,
        },
        Occurrences: copyAppt.Occurrences ?? [],
        ChangeType:
          isOldRecurringMaster && copyAppt.IsRecurringMaster
            ? RecurringChangeTypeEnum.ChangePattern
            : isOldRecurringMaster && !copyAppt.IsRecurringMaster
            ? RecurringChangeTypeEnum.RecurringToNormal
            : RecurringChangeTypeEnum.NormalToRecurring,
      });
    } else {
      // case intialize recurrence info.
      this._startedFlag.Recurrence = true;
      this._startSuccess();
    }
  }

  private _loadCustomProperties(context: any, callbacks: any) {
    let item = Office.context.mailbox.item;
    if (item && item.itemType === Office.MailboxEnums.ItemType.Appointment) {
      let appCompose = item as Office.AppointmentCompose;
      appCompose.loadCustomPropertiesAsync(callbacks, context);
    }
  }

  private _loadSessionData(_context: any, callbacks: any) {
    let item = Office.context.mailbox.item;
    if (item && item.itemType === Office.MailboxEnums.ItemType.Appointment) {
      let appCompose = item as Office.AppointmentCompose;
      appCompose.sessionData.getAllAsync(callbacks);
    }
  }

  private _watchRequiredRecipient(asyncResult: Office.AsyncResult<Array<Office.EmailAddressDetails>>) {
    try {
      let timeStamp = asyncResult.asyncContext.timeStamp as number;
      if (timeStamp < this._lastAttendeeSynctime && !Office.context.requirements.isSetSupported("Mailbox", "1.8"))
        return;
      if (asyncResult.status !== Office.AsyncResultStatus.Succeeded || asyncResult.value == null) {
        return;
      }
      let requiredRecipients: Array<Office.EmailAddressDetails> = asyncResult.value;
      let requiredOlRecipients: OutlookRecipient[] = requiredRecipients
        ? requiredRecipients.map((rs) => new OutlookRecipient(rs.emailAddress, rs.displayName))
        : [];

      let isResourcesUpdated = this._appointment.UpdateRequiredRecipients(requiredOlRecipients);

      if (isResourcesUpdated) {
        asyncResult.asyncContext.isRecipientsUpdated = true;
      }

      this._startedFlag.RequiredRecipients = true;

      //watch optional attendees
      let option: Office.AsyncContextOptions = {
        asyncContext: asyncResult.asyncContext,
      };
      let item = Office.context.mailbox.item;
      let appCompose = item as Office.AppointmentCompose;
      appCompose.optionalAttendees.getAsync(option, this._watchOptionalRecipient.bind(this));
    } catch (ex) {
      //console.log(ex);
    } finally {
    }
  }
  private _watchOptionalRecipient(asyncResult: Office.AsyncResult<Array<Office.EmailAddressDetails>>) {
    try {
      let timeStamp = asyncResult.asyncContext.timeStamp as number;
      if (timeStamp < this._lastAttendeeSynctime && !Office.context.requirements.isSetSupported("Mailbox", "1.8"))
        return;
      if (asyncResult.status !== Office.AsyncResultStatus.Succeeded || asyncResult.value == null) {
        return;
      }

      let optionalRecipients: Array<Office.EmailAddressDetails> = asyncResult.value;
      let optionalOlRecipients: OutlookRecipient[] = optionalRecipients
        ? optionalRecipients.map((rs) => new OutlookRecipient(rs.emailAddress, rs.displayName))
        : [];
      let isAttendeesUpdated = this._appointment.UpdateOptionalAttendees(optionalOlRecipients);

      if (isAttendeesUpdated) {
        asyncResult.asyncContext.isRecipientsUpdated = true;
      }
      this._startedFlag.OptionalRecipients = true;
      let item = Office.context.mailbox.item;
      let appCompose = item as Office.AppointmentCompose;
      let option: Office.AsyncContextOptions = {
        asyncContext: asyncResult.asyncContext,
      };

      if (Office.context.requirements.isSetSupported("Mailbox", "1.8")) {
        if (
          (appCompose as any) &&
          (appCompose.enhancedLocation as any) &&
          this._platformService.GetCurrentPlatform() === WebAddinPlatforms.OutlookForMac
        ) {
          let enhancedLoc: Office.EnhancedLocation = appCompose.enhancedLocation;
          enhancedLoc.getAsync(option, this._watchEnhancedLocations.bind(this));
        }
      } else {
        //watchResources for SetSupported < v1.8
        //if rcExtendedResources does not exist: check changes and fire event immediately
        try {
          if ((appCompose as any).rcExtendedResources) {
            (appCompose as any).rcExtendedResources.getAsync(option, this._watchRCExtendedResources.bind(this));
          }
        } catch (ex) {
          let option: Office.AsyncContextOptions = {
            asyncContext: asyncResult.asyncContext,
          };
          this._loadCustomProperties(option, this._watchRCExtendedCustomResources.bind(this));
        }
        // this._loadCustomProperties(option, this._watchRCExtendedResources.bind(this));
      }

      if (asyncResult.asyncContext.isRecipientsUpdated) {
        let allRecipients = this._appointment.ExportRecipients();
        this.OnRecipientsChanged.Fire(allRecipients);
        this._notifyChange();
      }

      this._startSuccess();
    } catch (ex) {
      //console.log(ex);
    } finally {
    }
  }
  private _watchEnhancedLocations(asyncResult: Office.AsyncResult<Office.LocationDetails[]>) {
    try {
      let timeStamp = asyncResult.asyncContext.timeStamp as number;
      if (timeStamp < this._lastAttendeeSynctime && !Office.context.requirements.isSetSupported("Mailbox", "1.8")) {
        return;
      }
      if (asyncResult.status !== Office.AsyncResultStatus.Succeeded) {
        this._startedFlag.Resources = true;
        this._startSuccess();
        return;
      }
      let rcResources: Office.LocationDetails[] = asyncResult.value;
      let olResources: OutlookRecipient[] = rcResources
        ? rcResources.map((rs) => {
            let emailAddress = rs.emailAddress;
            if (
              !emailAddress &&
              rs.locationIdentifier &&
              this._platformService.GetCurrentPlatform() === WebAddinPlatforms.OutlookForMac
            ) {
              emailAddress = rs.locationIdentifier.id;
            }
            return new OutlookRecipient(emailAddress, rs.displayName);
          })
        : [];

      let isResourcesUpdated = this._appointment.UpdateResources(olResources);
      if (isResourcesUpdated) {
        asyncResult.asyncContext.isRecipientsUpdated = true;
      }
      if (asyncResult.asyncContext.isRecipientsUpdated) {
        let allRecipients = this._appointment.ExportRecipients();
        this.OnRecipientsChanged.Fire(allRecipients);
        this._notifyChange();
      }

      this._startedFlag.Resources = true;
      this._startSuccess();
    } catch (ex) {
      //console.log(ex);
    } finally {
    }
  }

  private _watchRCExtendedCustomResources(asyncResult: Office.AsyncResult<Office.CustomProperties>) {
    try {
      let customProperties: Office.CustomProperties = asyncResult.value;
      let customRcExtendedResources = customProperties.get(this._rcExtendedResourcesKey);
      // let timeStampStr = customProperties.get(this._rcExtendedResourcesTimeStampKey);
      if (!customRcExtendedResources) return;
      //if (!timeStampStr)
      //timeStampStr = '0';
      let newResources = JSON.parse(customRcExtendedResources);
      let rcResources: Array<Office.EmailUser> = newResources.map((rs: any) => {
        let olRecipient: Office.EmailUser = {
          displayName: rs.displayName,
          emailAddress: rs.emailAddress,
        };
        return olRecipient;
      });

      let timeStamp = asyncResult.asyncContext.asyncContext.timeStamp as number;
      if (timeStamp < this._lastAttendeeSynctime) return;
      if (asyncResult.status !== Office.AsyncResultStatus.Succeeded || asyncResult.value == null) {
        return;
      }
      //let rcResources: Array<Office.EmailAddressDetails> = asyncResult.value;
      let olResources: OutlookRecipient[] = rcResources
        ? rcResources.map((rs) => new OutlookRecipient(rs.emailAddress, rs.displayName))
        : [];

      let isResourcesUpdated = this._appointment.UpdateResources(olResources);
      if (isResourcesUpdated) {
        asyncResult.asyncContext.isRecipientsUpdated = true;
      }

      if (asyncResult.asyncContext.isRecipientsUpdated) {
        let allRecipients = this._appointment.ExportRecipients();
        this.OnRecipientsChanged.Fire(allRecipients);
        this._notifyChange();
      }

      this._startedFlag.Resources = true;
      this._startSuccess();
    } catch (ex) {
      //console.log(ex);
    } finally {
    }
  }
  private _watchRCExtendedResources(asyncResult: Office.AsyncResult<Array<Office.EmailAddressDetails>>) {
    try {
      let timeStamp = asyncResult.asyncContext.timeStamp as number;
      if (timeStamp < this._lastAttendeeSynctime) return;
      if (asyncResult.status !== Office.AsyncResultStatus.Succeeded || asyncResult.value == null) {
        return;
      }
      let option: Office.AsyncContextOptions = {
        asyncContext: asyncResult.asyncContext,
      };
      this._loadCustomProperties(option, this._watchRCExtendedCustomResources.bind(this));

      //let rcResources: Array<Office.EmailAddressDetails> = asyncResult.value;
      //let olResources: OutlookRecipient[] = rcResources ? rcResources.map(rs => new OutlookRecipient(rs.emailAddress, rs.displayName)) : [];

      //let isResourcesUpdated = this._appointment.UpdateResources(olResources);
      //if (isResourcesUpdated) {
      //    asyncResult.asyncContext.isRecipientsUpdated = true;
      //}

      //if (asyncResult.asyncContext.isRecipientsUpdated) {
      //    let allRecipients = this._appointment.ExportRecipients();
      //    this.OnRecipientsChanged.Fire(allRecipients);
      //    this._notifyChange();
      //}

      this._startedFlag.Resources = true;
      this._startSuccess();
    } catch (ex) {
      //console.log(ex);
    } finally {
    }
  }
  private _watchStartTime(asyncResult: Office.AsyncResult<Date>) {
    try {
      if (asyncResult.status !== Office.AsyncResultStatus.Succeeded || asyncResult.value == null) {
        return;
      }
      // if start time is locked, stop process.
      if (this._locker.DateTime) {
        let intervalId = setInterval(() => {
          if (!this._locker.DateTime) {
            clearInterval(intervalId);
          }
        }, 1);
      }
      this._locker.DateTime = true;

      //let isStartTimeChanged = this._getStartTimeResult(asyncResult);
      // process get end time.
      let item = Office.context.mailbox.item;
      if (item && item.itemType === Office.MailboxEnums.ItemType.Appointment) {
        let appCompose = item as Office.AppointmentCompose;

        let asyncContextStart: GetDateTimeAsyncContext = {
          Done: (isStartTimeChanged: boolean) => {
            try {
              let asyncContext: GetDateTimeAsyncContext = {
                Done: (isEndTimeChanged: boolean) => {
                  try {
                    if (isEndTimeChanged || isStartTimeChanged) {
                      this._processChangeDateTime();
                    }
                    this._startedFlag.EndTime = true;
                    this._startSuccess();
                  } catch (ex) {
                    //console.log(ex);
                  } finally {
                    this._locker.DateTime = false;
                  }
                  if (asyncResult.asyncContext != null) {
                    let asyncContext = asyncResult.asyncContext as GetDateTimeAsyncContext;
                    asyncContext.Done(true);
                  }
                },
              };
              let options: Office.AsyncContextOptions = {
                asyncContext: asyncContext,
              };
              appCompose.end.getAsync(options, this._getEndTimeResult.bind(this));
              this._startedFlag.StartTime = true;
              this._startSuccess();
            } catch (ex) {
              //console.log(ex);
            } finally {
              this._locker.DateTime = false;
            }
            this._startedFlag.StartTime = true;
            this._startSuccess();
          },
        };
        let optionsStart: Office.AsyncContextOptions = {
          asyncContext: asyncContextStart,
        };
        appCompose.start.getAsync(optionsStart, this._getStartTimeResult.bind(this));
      }
    } catch (ex) {
      //console.log(ex);
      this._locker.DateTime = false;
    }
  }
  private async _getStartTimeResult(asyncResult: Office.AsyncResult<Date>): Promise<boolean> {
    let isStartTimeUpdated = false;
    try {
      let start: Date = asyncResult.value;
      // if (Office.context.platform == Office.PlatformType.OfficeOnline) {
      //   let convertDT = Office.context.mailbox.convertToLocalClientTime(start);
      //   start = Office.context.mailbox.convertToUtcClientTime(convertDT);
      // }

      isStartTimeUpdated = await this._appointment.UpdateStartTime(start);
    } catch (ex) {
      //console.log(ex);
    }
    if (asyncResult.asyncContext != null) {
      let asyncContext = asyncResult.asyncContext as GetDateTimeAsyncContext;
      asyncContext.Done(isStartTimeUpdated);
    }
    return isStartTimeUpdated;
  }
  private async _getEndTimeResult(asyncResult: Office.AsyncResult<Date>): Promise<boolean> {
    let isEndTimeUpdated = false;
    try {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded && asyncResult.value != null) {
        let end: Date = asyncResult.value;
        // if (Office.context.platform == Office.PlatformType.OfficeOnline) {
        //   let convertDT = Office.context.mailbox.convertToLocalClientTime(end);
        //   end = Office.context.mailbox.convertToUtcClientTime(convertDT);
        // }
        isEndTimeUpdated = await this._appointment.UpdateEndTime(end);
      }
    } catch (ex) {
      //console.log(ex);
    }
    if (asyncResult.asyncContext != null) {
      let asyncContext = asyncResult.asyncContext as GetDateTimeAsyncContext;
      asyncContext.Done(isEndTimeUpdated);
    }
    return isEndTimeUpdated;
  }
  private _processChangeDateTime() {
    // process notify the change date/time event if and only if the appointment is not recurring series.
    // because in this case, the change recurrence event is notified insteads.
    if (!this._appointment.IsRecurringMaster) {
      this._notifyChange();
      let copyAppt = this._appointment.Export();
      let eventArg: DateTimePeriodDto = {
        StartTicks: copyAppt.StartTimeTicks,
        EndTicks: copyAppt.EndTimeTicks,
        Start: copyAppt.StartTime,
        End: copyAppt.EndTime,
      };
      this.OnDateTimeChanged.Fire(eventArg);
    }
  }
  private _watchSubject(asyncResult: Office.AsyncResult<string>) {
    try {
      if (asyncResult.status !== Office.AsyncResultStatus.Succeeded || asyncResult.value == null) {
        return;
      }
      // if subject in appointment is locked, stop process.
      if (this._locker.Subject) {
        let intervalId = setInterval(() => {
          if (!this._locker.DateTime) {
            clearInterval(intervalId);
          }
        }, 1);
      }
      this._locker.Subject = true;
      let subject = asyncResult.value;
      let isSubjectUpdated = this._appointment.UpdateSubject(subject);
      if (isSubjectUpdated) {
        this._notifyChange();
      }
      this._startedFlag.Subject = true;
      this._startSuccess();
    } catch (ex) {
      //console.log(ex);
    } finally {
      this._locker.Subject = false;
    }
  }

  private async getUidForDesktop(): Promise<string> {
    try {
      let getMessageUrl = await this.GetMessageUrlCallback();
      getMessageUrl = getMessageUrl.replace(
        "?$select=iCalUId,originalStart",
        `?&$expand=singleValueExtendedProperties($filter=id eq 'String {00020329-0000-0000-C000-000000000046} Name cecp-54abccb0-533f-4f37-9b9e-b4834331f0b1')&$select=id`
      );
      const authenticationService: AuthenticationService = new AuthenticationService();
      const accessToken = await authenticationService.GetCallbackToken();
      return axios
        .get(getMessageUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((res) => {
          const singleValueExtendedPropertiesValue = JSON.parse(
            res.data?.singleValueExtendedProperties[0] && res.data?.singleValueExtendedProperties[0]?.value
              ? res.data?.singleValueExtendedProperties[0]?.value
              : "{}"
          );
          return singleValueExtendedPropertiesValue?.CUSTOM_UID || "";
        })
        .catch(() => {
          return "";
        });
    } catch {
      return "";
    }
  }

  private async _loadCustomPropertiesCallback(asyncResult: Office.AsyncResult<Office.CustomProperties>) {
    let customProperties: Office.CustomProperties = asyncResult.value;
    let customUid = customProperties.get(this._customUidKey);
    if (!customUid) {
      let currentPlatform = this._platformService.GetCurrentPlatform();
      if (currentPlatform === WebAddinPlatforms.DesktopClient || currentPlatform === WebAddinPlatforms.Outlook2016) {
        const uidApi = await this.getUidForDesktop();
        if (uidApi) {
          customUid = uidApi;
        }
      }
    }
    let uid = customProperties.get(this._uidKey);
    if (uid && uid != "") {
      this._appointment.Uid = uid;
    }
    if (customUid && customUid != "") {
      if (!uid || uid == "") {
        this._appointment.Uid = customUid;
      }
      this._appointment.CustomUid = customUid;
    } else {
      if (this._appointment.CustomUid && this._appointment.CustomUid != "") {
        customProperties.set(this._customUidKey, this._appointment.CustomUid);
        customProperties.saveAsync();
      }
    }
    this._startedFlag.CustomProperties = true;
    this._startSuccess();
  }
  

  private _setCustomRcExtendedResources(receipients: Array<Office.EmailUser>, isAdd: boolean) {
    if ((receipients != null && receipients.length > 0) || !isAdd) {
      let properties: IHash<string> = {};
      properties[this._rcExtendedResourcesKey] = JSON.stringify(receipients);
      // properties[this._rcExtendedResourcesTimeStampKey] = Date.now().toString();
      this._lastAttendeeSynctime = Date.now();
      //this._setCustomProperty(properties);
      let asyncContext: SetCustomPropertiesAsyncContext = {
        Properties: properties,
        IsAdd: isAdd,
      };
      this._loadCustomProperties(asyncContext, this._setCustomRcExtendedResourcesCallback.bind(this));
    }
  }

  private _setCustomRcExtendedResourcesCallback(asyncResult: Office.AsyncResult<Office.CustomProperties>) {
    if (asyncResult.asyncContext) {
      let customProperties: Office.CustomProperties = asyncResult.value;
      if (customProperties) {
        let asyncContext = asyncResult.asyncContext as SetCustomPropertiesAsyncContext;
        if (asyncContext.Properties) {
          for (let key in asyncContext.Properties) {
            if (key == this._rcExtendedResourcesKey && asyncContext.IsAdd) {
              //current
              let customRcExtendedResources = customProperties.get(this._rcExtendedResourcesKey);
              if (customRcExtendedResources) {
                let currentResources = JSON.parse(customRcExtendedResources);
                let rcResources: Array<Office.EmailUser> = currentResources.map((rs: any) => {
                  let olRecipient: Office.EmailUser = {
                    displayName: rs.displayName,
                    emailAddress: rs.emailAddress,
                  };
                  return olRecipient;
                });
                //new
                let newResources = JSON.parse(asyncContext.Properties[key]);
                let rcNewResources: Array<Office.EmailUser> = newResources.map((rs: any) => {
                  let olRecipient: Office.EmailUser = {
                    displayName: rs.displayName,
                    emailAddress: rs.emailAddress,
                  };
                  return olRecipient;
                });
                rcNewResources.forEach((t) => {
                  var existed = rcResources.filter(
                    (x) => x.displayName == t.displayName && x.emailAddress == t.emailAddress
                  );
                  if (existed.length <= 0) {
                    rcResources.push(t);
                  }
                });
                customProperties.set(key, JSON.stringify(rcResources));
              } else {
                customProperties.set(key, asyncContext.Properties[key]);
              }
            } else {
              customProperties.set(key, asyncContext.Properties[key]);
            }
          }
          customProperties.saveAsync(this.saveCallback);
        }
      }
    }
  }

  private clearAllCustomProperties(): void {
    const item = Office.context.mailbox.item;
    if (item) {
      item.loadCustomPropertiesAsync((asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
          // Handle error
          console.error("Failed to load custom properties:", asyncResult.error.message);
          return;
        }

        const customProps = asyncResult.value;
        const allKeys = customProps.getAll();
        for (const key in allKeys) {
          if (allKeys.hasOwnProperty(key)) {
            customProps.remove(key);
          }
        }

        // Save the changes
        customProps.saveAsync((saveAsyncResult) => {
          if (saveAsyncResult.status === Office.AsyncResultStatus.Failed) {
            // Handle error on save
            console.error("Failed to save custom properties:", saveAsyncResult.error.message);
          } else {
            //console.log("All custom properties cleared.");
          }
        });
      });
    }
  }

  saveCallback = (asyncResult: Office.AsyncResult<void>): void => {
    if (asyncResult.status === Office.AsyncResultStatus.Failed) {
      console.error(asyncResult.error.message);
    } else {
      const item = Office.context.mailbox.item;
      if (item) {
        console.log(
          item.loadCustomPropertiesAsync((a) => {
            console.log(a.value.getAll());
          })
        );
      }
      // Proceed with the appropriate action for your add-in.
    }
  };

  public UpdateBodyForSendToAll(_key: string, sendToAll: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (sendToAll) {
        let currentPlatform = this._platformService.GetCurrentPlatform();
        if (WebAddinPlatforms.OutlookForMac === currentPlatform || WebAddinPlatforms.OWA === currentPlatform) {
          let item = Office.context.mailbox.item;
          let appCompose = item as Office.AppointmentCompose;
          if (appCompose) {
            let htmlOptions: Office.AsyncContextOptions & Office.CoercionTypeOptions = {
              asyncContext: null,
              coercionType: Office.CoercionType.Html,
            };
            appCompose.body.getAsync(Office.CoercionType.Html, (asyncResult: Office.AsyncResult<string>) => {
              if (Office.AsyncResultStatus.Succeeded === asyncResult.status) {
                let prependedBody = asyncResult.value + " ";
                appCompose.body.setAsync(prependedBody, htmlOptions, (asyncResult: any) => {
                  if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                    resolve(true);
                  } else {
                    resolve(false);
                  }
                });
              } else {
                resolve(false);
              }
            });
          } else {
            resolve(false);
          }
        } else {
          resolve(false);
        }
      } else {
        resolve(false);
      }
    });
  }
  private _setCustomProperty(properties: IHash<string>) {
    let asyncContext: SetCustomPropertiesAsyncContext = {
      Properties: properties,
    };
    this._loadCustomProperties(asyncContext, this._setCustomPropertyCallback.bind(this));
  }
  private _setCustomPropertyCallback(asyncResult: Office.AsyncResult<Office.CustomProperties>) {
    if (asyncResult.asyncContext) {
      let customProperties: Office.CustomProperties = asyncResult.value;
      if (customProperties) {
        let asyncContext = asyncResult.asyncContext as SetCustomPropertiesAsyncContext;
        if (asyncContext.Properties) {
          for (let key in asyncContext.Properties) {
            customProperties.set(key, asyncContext.Properties[key]);
          }
          customProperties.saveAsync();
        }
      }
    }
  }
  private _notifyChange() {
    if (this.OnChanged) {
      this.OnChanged.Fire(this._appointment.Export());
    }
  }
  public IsNewBooking(): boolean {
    return this._appointment.IsNewBooking();
  }

  private _extendAppointment() {
    let appCompose = Office.context.mailbox.item as any;
    if (!appCompose.rcExtendedResources) {
      appCompose.rcExtendedResources = {
        _propertyName$p$0: "rcExtendedResources",
        _type$p$0: 0,
      };
      appCompose.rcExtendedResources = appCompose.requiredAttendees;
      appCompose.rcExtendedResources._propertyName$p$0 = "rcExtendedResources";
      appCompose.rcExtendedResources._type$p$0 = 0;
    }
  }

  SaveDataResourceInfo(data: Record<string, any>, key: string): void {
    const item = Office.context.mailbox.item;
    if (item) {
      item.sessionData.setAsync(key, JSON.stringify(data));
    }
  }

  ConvertTimeDisplayLocalUTC(dateTime: Date): Date {
    if (Office.context.platform == Office.PlatformType.OfficeOnline) {
      let convertDT = Office.context.mailbox.convertToLocalClientTime(dateTime);
      dateTime = Office.context.mailbox.convertToUtcClientTime(convertDT);
      return dateTime;
    }
    console.log("dateTime", dateTime);

    return dateTime;
  }

  CreateNewUid(): Promise<string> {
    const uid = UidGenerator.NewUid();
    return new Promise((resolve, reject) => {
      let properties: IHash<string> = {};
      properties[this._customUidKey] = uid;
      let asyncContext: SetCustomPropertiesAsyncContext = {
        Properties: properties,
      };
      this._loadCustomProperties(asyncContext, (asyncResult: Office.AsyncResult<Office.CustomProperties>) => {
        if (asyncResult.asyncContext) {
          let customProperties: Office.CustomProperties = asyncResult.value;
          if (customProperties) {
            let asyncContext = asyncResult.asyncContext as SetCustomPropertiesAsyncContext;
            if (asyncContext.Properties) {
              for (let key in asyncContext.Properties) {
                customProperties.set(key, asyncContext.Properties[key]);
              }
              customProperties.saveAsync();
              resolve(uid)
            } else {
              reject("")
            }
          } else {
            reject("")
          }
        }
      });
    })
  }
}
// let resVersion = Office.MailboxEnums.RestVersion.v2_0;
//                 let strRestid = Office.context.mailbox.convertToRestId(currentAppt.ItemId, resVersion);
//                 let getMessageUrl = Office.context.mailbox.restUrl + '/v2.0/me/events/' + strRestid + "?$select=iCalUId,originalStart";
interface GetDateTimeAsyncContext {
  Done: (args?: any) => void;
}

interface SetCustomPropertiesAsyncContext {
  Properties: IHash<string>;
  IsAdd?: boolean;
}
