import {
  addDates,
  addMinutes,
  addMonths,
  addWeeks,
  addYears,
  CloneDateTime,
  CloneDateTimeFromSeparateSource,
  convertToNetTicks,
  DateTimePeriodDto,
  IPlatformService,
  //   ITimeZoneHelperService,
  StringToDate,
  StringToTime,
  TokenIPlatformService,
  WebAddinPlatforms,
  //   TokenITimeZoneHelperService,
} from "booking-app-op";
import { container } from "tsyringe";

interface DateTimePeriod {
  Start: Date;
  End: Date;
}
export class RecurringHelper {
  static async GetRecurringDates(
    recurrencePattern: Office.Recurrence,
    noEndOccurrences: number
  ): Promise<Array<DateTimePeriodDto>> {
    const recurringOccs: DateTimePeriodDto[] = [];
    // get first occ from start time.
    let occurrence = this.CalculateFirstOccurrence(recurrencePattern);
    let endDate: Date | null = null;
    const endDateStr = recurrencePattern.seriesTime.getEndDate();
    let maxNumberOfOccs = 0;
    if (endDateStr == null) {
      maxNumberOfOccs = noEndOccurrences;
    } else {
      endDate = StringToDate(endDateStr);
      endDate = addDates(endDate, 1);
    }
    while (
      (occurrence && endDate && occurrence.Start < endDate) ||
      (maxNumberOfOccs > 0 && recurringOccs.length < maxNumberOfOccs)
    ) {
      recurringOccs.push({
        StartTicks: convertToNetTicks(occurrence?.Start),
        EndTicks: convertToNetTicks(occurrence?.End),
        Start: occurrence?.Start,
        End: occurrence?.End,
        StartInOrgTz: occurrence?.Start,
        EndInOrgTz: occurrence?.End,
      });
      occurrence = occurrence && this.GetNextOccurrence(recurrencePattern, occurrence);
      //console.log("occurrence", occurrence)
    }

    const platformService: IPlatformService = container.resolve(TokenIPlatformService);
    if(recurrencePattern.recurrenceType === Office.MailboxEnums.RecurrenceType.Weekly && (platformService.GetCurrentPlatform() === WebAddinPlatforms.OWA || platformService.GetCurrentPlatform() === WebAddinPlatforms.DesktopClient)) {
      const dayOfWeekNumber: number[] = [];
      const propertiesDayOfWeek = recurrencePattern.recurrenceProperties.dayOfWeek ? [recurrencePattern.recurrenceProperties.dayOfWeek] : recurrencePattern.recurrenceProperties.days
      for (let i = 0; i < propertiesDayOfWeek.length; i++) {
        let dayOfweek: Office.MailboxEnums.Days = this.MapDaysOfWeekString(propertiesDayOfWeek[i]);
        let daysOfWeekInNumber = this.MapDaysOfWeekToNumber(dayOfweek);
        dayOfWeekNumber.push(daysOfWeekInNumber)
      }
      if(recurringOccs[0]) {
        let firstOccDay = recurringOccs[0].Start.getDay();
        if(dayOfWeekNumber.length && !dayOfWeekNumber.includes(firstOccDay)) {
          recurringOccs[0].IsDeleted = true;
        }
      }
    }
    // let timeZoneHelperService: ITimeZoneHelperService = container.resolve(TokenITimeZoneHelperService);
    // let timeToConvert: number[] = [];
    // recurringOccs.forEach((i) => {
    //   timeToConvert.push(i.StartTicks);
    //   timeToConvert.push(i.EndTicks);
    // });
    // let convertedDatetime = await timeZoneHelperService.ConvertDateTimes({
    //   DateTimeTicksToConvert: timeToConvert,
    //   OriginalTimeZone: organizerTimeZone,
    //   ClientTimeZone: clientTimeZone,
    //   TargetTimeZone: clientTimeZone,
    // });
    // if (convertedDatetime) {
    //   recurringOccs.forEach((i) => {
    //     let convertedStart = convertedDatetime[i.StartTicks];
    //     if (convertedStart) {
    //       i.Start = convertedStart.ConvertedDateTime;
    //       i.StartTicks = convertToNetTicks(convertedStart.ConvertedDateTime);
    //     }
    //     let convertedEnd = convertedDatetime[i.EndTicks];
    //     if (convertedEnd) {
    //       i.End = convertedEnd.ConvertedDateTime;
    //       i.EndTicks = convertToNetTicks(convertedEnd.ConvertedDateTime);
    //     }
    //   });
    // }
    return recurringOccs;
  }
  private static CalculateFirstOccurrence(pattern: Office.Recurrence): DateTimePeriod | undefined {
    let firstOcc: DateTimePeriod | undefined;
    let seriesTime = pattern.seriesTime;
    if (seriesTime) {
      let startDate = StringToDate(seriesTime.getStartDate());
      let startTime = StringToTime(seriesTime.getStartTime());
      let duration = seriesTime.getDuration();
      let startDateTime = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        startTime.getHours(),
        startTime.getMinutes(),
        startTime.getSeconds()
      );
      let endDateTime = addMinutes(startDateTime, duration);
      firstOcc = {
        Start: startDateTime,
        End: endDateTime,
      };
    }
    return firstOcc;
  }
  private static GetNextOccurrence(pattern: Office.Recurrence, prevOcc: DateTimePeriod) {
    let occDaysOfNextMonth;
    let nextOcc: DateTimePeriod | undefined;
    let properties = pattern.recurrenceProperties;
    switch (pattern.recurrenceType) {
      case Office.MailboxEnums.RecurrenceType.Daily:
        if (properties?.interval && prevOcc?.Start && prevOcc?.End) {
          nextOcc = {
            Start: addDates(prevOcc.Start, properties.interval),
            End: addDates(prevOcc.End, properties.interval),
          };
        }
        break;
      case Office.MailboxEnums.RecurrenceType.Weekday:
        let olDaysOfWeekEnum = Office.MailboxEnums.Days;
        let daysOfWeek = olDaysOfWeekEnum.Sun;
        nextOcc = {
          Start: CloneDateTime(prevOcc.Start),
          End: CloneDateTime(prevOcc.End),
        };
        do {
          if (nextOcc?.Start && nextOcc?.End) {
            nextOcc = {
              Start: addDates(nextOcc.Start, 1),
              End: addDates(nextOcc.End, 1),
            };
          }
          daysOfWeek = this.MapDaysOfWeek(nextOcc.Start.getDay());
        } while (
          // get the next day if the current day is in sunday or saturday.
          daysOfWeek === olDaysOfWeekEnum.Sun ||
          daysOfWeek === olDaysOfWeekEnum.Sat
        );
        break;
      case Office.MailboxEnums.RecurrenceType.Weekly:
        const prevDaysOfWeek = prevOcc.Start.getDay();
        let minDaysOfWeek = 6;
        let nextOccDays = 6;
        let maxDaysOfWeek = 0;
        if (properties?.days && properties.days.length > 0) {
          for (let i = 0; i < properties.days.length; i++) {
            let dayOfweek: Office.MailboxEnums.Days = this.MapDaysOfWeekString(properties.days[i]);
            let daysOfWeekInNumber = this.MapDaysOfWeekToNumber(dayOfweek);
            if (daysOfWeekInNumber < minDaysOfWeek) {
              minDaysOfWeek = daysOfWeekInNumber;
            }
            if (daysOfWeekInNumber > prevDaysOfWeek) {
              if (daysOfWeekInNumber < nextOccDays) {
                nextOccDays = daysOfWeekInNumber;
              }
            }
            if (daysOfWeekInNumber > maxDaysOfWeek) {
              maxDaysOfWeek = daysOfWeekInNumber;
            }
          }
          //properties.days.forEach((day) => {
          //    let dayOfweek: Office.MailboxEnums.Days = (<any>Office.MailboxEnums.Days)[day];
          //    var daysOfWeekInNumber = this.MapDaysOfWeekToNumber(dayOfweek);
          //    if (daysOfWeekInNumber < minDaysOfWeek) {
          //        minDaysOfWeek = daysOfWeekInNumber;
          //    }
          //    if (daysOfWeekInNumber > prevDaysOfWeek) {
          //        if (daysOfWeekInNumber < nextOccDays) {
          //            nextOccDays = daysOfWeekInNumber;
          //        }
          //    }
          //    if (daysOfWeekInNumber > maxDaysOfWeek) {
          //        maxDaysOfWeek = daysOfWeekInNumber;
          //    }
          //});
        }
        if (prevDaysOfWeek === maxDaysOfWeek) {
          // if the prev occur is the last occ of this week, add the interval to move the next recurring weeks.
          const nextRecurringWeek = addWeeks(prevOcc.Start, properties?.interval || 1);
          const nextOccDaysOfWeek = addDates(nextRecurringWeek, minDaysOfWeek - prevDaysOfWeek);
          nextOcc = {
            Start: CloneDateTimeFromSeparateSource(nextOccDaysOfWeek, prevOcc.Start),
            End: CloneDateTimeFromSeparateSource(nextOccDaysOfWeek, prevOcc.End),
          };
        } else {
          nextOcc = {
            Start: addDates(prevOcc.Start, nextOccDays - prevDaysOfWeek || 1),
            End: addDates(prevOcc.End, nextOccDays - prevDaysOfWeek || 1),
          };
        }
        break;
      case Office.MailboxEnums.RecurrenceType.Monthly:
        if (typeof properties?.dayOfMonth !== "undefined") {
          // case has the specific days of month
          nextOcc = {
            Start: addMonths(prevOcc.Start, properties.interval || 1),
            End: addMonths(prevOcc.End, properties.interval || 1),
          };
        } else if (properties?.interval !== undefined) {
          // case use week days
          const nextMonthRepDays = addMonths(prevOcc.Start, properties.interval || 1);
          const firstDaysInNextMonth = new Date(
            nextMonthRepDays.getFullYear(),
            nextMonthRepDays.getMonth(),
            1,
            nextMonthRepDays.getHours(),
            nextMonthRepDays.getMinutes(),
            nextMonthRepDays.getSeconds()
          );
          occDaysOfNextMonth = this.GetOccDaysOfMonth(firstDaysInNextMonth, pattern);
          nextOcc = {
            Start: CloneDateTimeFromSeparateSource(occDaysOfNextMonth, prevOcc.Start),
            End: CloneDateTimeFromSeparateSource(occDaysOfNextMonth, prevOcc.End),
          };
        }
        break;
      case Office.MailboxEnums.RecurrenceType.Yearly:
        if (typeof properties?.dayOfMonth !== "undefined") {
          // case has the specific days and month year.
          nextOcc = {
            Start: addYears(prevOcc.Start, properties.interval || 1),
            End: addYears(prevOcc.End, properties.interval || 1),
          };
        } else if (properties?.interval !== undefined) {
          const nextOccYear = addYears(prevOcc.Start, properties.interval || 1);
          const firstDayOfNextOccMonth = new Date(
            nextOccYear.getFullYear(),
            nextOccYear.getMonth(),
            1,
            nextOccYear.getHours(),
            nextOccYear.getMinutes(),
            nextOccYear.getSeconds()
          );
          occDaysOfNextMonth = this.GetOccDaysOfMonth(firstDayOfNextOccMonth, pattern);
          nextOcc = {
            Start: CloneDateTimeFromSeparateSource(occDaysOfNextMonth, prevOcc.Start),
            End: CloneDateTimeFromSeparateSource(occDaysOfNextMonth, prevOcc.End),
          };
        }
        break;
      default:
        break;
    }
    return nextOcc;
  }
  private static GetOccDaysOfMonth(firstDaysOfMonth: Date, pattern: Office.Recurrence): Date {
    let properties = pattern.recurrenceProperties;
    let occDaysOfWeeks: Office.MailboxEnums.Days | undefined = properties?.dayOfWeek
      ? this.MapDaysOfWeekString(properties.dayOfWeek)
      : undefined;
    const occDaysOfWeeksInNumber = occDaysOfWeeks ? this.MapDaysOfWeekToNumber(occDaysOfWeeks) : undefined;
    let olWeekNumberTypeEnum = Office.MailboxEnums.WeekNumber;

    let occDaysOfMonth = new Date();
    let occDateInFirstWeek = new Date();
    const firstDaysInNextMonthDaysOfWeek = firstDaysOfMonth.getDay();
    if (occDaysOfWeeksInNumber) {
      if (firstDaysInNextMonthDaysOfWeek <= occDaysOfWeeksInNumber) {
        occDateInFirstWeek = addDates(firstDaysOfMonth, occDaysOfWeeksInNumber - firstDaysInNextMonthDaysOfWeek);
      } else {
        occDateInFirstWeek = addDates(
          addWeeks(firstDaysOfMonth, 1),
          occDaysOfWeeksInNumber - firstDaysInNextMonthDaysOfWeek
        );
      }
    }
    if ((properties?.weekNumber as Office.MailboxEnums.WeekNumber) === olWeekNumberTypeEnum.Last) {
      let lastOccDateInMonth = addWeeks(occDateInFirstWeek, 4);
      if (lastOccDateInMonth.getMonth() != firstDaysOfMonth.getMonth()) {
        // case this month only has 4 occurrences week days.
        lastOccDateInMonth = addWeeks(occDateInFirstWeek, 3);
      }
      occDaysOfMonth = lastOccDateInMonth;
    } else if (properties?.weekNumber) {
      let occWeekNumber: Office.MailboxEnums.WeekNumber = this.MapWeekNumberByString(properties.weekNumber);
      const weeksInMonthNumber = this.MapWeekNumber(occWeekNumber);
      occDaysOfMonth = addWeeks(occDateInFirstWeek, weeksInMonthNumber - 1);
    }
    return occDaysOfMonth;
  }

  private static MapDaysOfWeekString(dayOfWeek: string): Office.MailboxEnums.Days {
    let olDaysOfWeek = Office.MailboxEnums.Days.Sun;
    switch (dayOfWeek) {
      case "sun":
        olDaysOfWeek = Office.MailboxEnums.Days.Sun;
        break;
      case "mon":
        olDaysOfWeek = Office.MailboxEnums.Days.Mon;
        break;
      case "tue":
        olDaysOfWeek = Office.MailboxEnums.Days.Tue;
        break;
      case "wed":
        olDaysOfWeek = Office.MailboxEnums.Days.Wed;
        break;
      case "thu":
        olDaysOfWeek = Office.MailboxEnums.Days.Thu;
        break;
      case "fri":
        olDaysOfWeek = Office.MailboxEnums.Days.Fri;
        break;
      case "sat":
        olDaysOfWeek = Office.MailboxEnums.Days.Sat;
        break;
      case "weekday":
        olDaysOfWeek = Office.MailboxEnums.Days.Weekday;
        break;
      case "weekendDay":
        olDaysOfWeek = Office.MailboxEnums.Days.WeekendDay;
        break;
      case "day":
        olDaysOfWeek = Office.MailboxEnums.Days.Day;
        break;
    }
    return olDaysOfWeek;
  }

  private static MapDaysOfWeek(dayOfWeek: number): Office.MailboxEnums.Days {
    let olDaysOfWeekEnum = Office.MailboxEnums.Days;
    let olDaysOfWeek = olDaysOfWeekEnum.Sun;
    switch (dayOfWeek) {
      case 0:
        olDaysOfWeek = olDaysOfWeekEnum.Sun;
        break;
      case 1:
        olDaysOfWeek = olDaysOfWeekEnum.Mon;
        break;
      case 2:
        olDaysOfWeek = olDaysOfWeekEnum.Tue;
        break;
      case 3:
        olDaysOfWeek = olDaysOfWeekEnum.Wed;
        break;
      case 4:
        olDaysOfWeek = olDaysOfWeekEnum.Thu;
        break;
      case 5:
        olDaysOfWeek = olDaysOfWeekEnum.Fri;
        break;
      case 6:
        olDaysOfWeek = olDaysOfWeekEnum.Sat;
        break;
    }
    return olDaysOfWeek;
  }

  private static MapDaysOfWeekToNumber(dayOfWeek: Office.MailboxEnums.Days): number {
    let olDaysOfWeekEnum = Office.MailboxEnums.Days;
    let daysOfWeekInNumber = 0;
    switch (dayOfWeek) {
      case olDaysOfWeekEnum.Sun:
        daysOfWeekInNumber = 0;
        break;
      case olDaysOfWeekEnum.Mon:
        daysOfWeekInNumber = 1;
        break;
      case olDaysOfWeekEnum.Tue:
        daysOfWeekInNumber = 2;
        break;
      case olDaysOfWeekEnum.Wed:
        daysOfWeekInNumber = 3;
        break;
      case olDaysOfWeekEnum.Thu:
        daysOfWeekInNumber = 4;
        break;
      case olDaysOfWeekEnum.Fri:
        daysOfWeekInNumber = 5;
        break;
      case olDaysOfWeekEnum.Sat:
        daysOfWeekInNumber = 6;
        break;
    }
    return daysOfWeekInNumber;
  }

  private static MapWeekNumberByString(weekNumber: string): Office.MailboxEnums.WeekNumber {
    let olWeekNumberTypeEnum = Office.MailboxEnums.WeekNumber.First;
    switch (weekNumber) {
      case "first":
        olWeekNumberTypeEnum = Office.MailboxEnums.WeekNumber.First;
        break;
      case "second":
        olWeekNumberTypeEnum = Office.MailboxEnums.WeekNumber.Second;
        break;
      case "third":
        olWeekNumberTypeEnum = Office.MailboxEnums.WeekNumber.Third;
        break;
      case "fourth":
        olWeekNumberTypeEnum = Office.MailboxEnums.WeekNumber.Fourth;
        break;
      default:
        olWeekNumberTypeEnum = Office.MailboxEnums.WeekNumber.Last;
        break;
    }
    return olWeekNumberTypeEnum;
  }

  private static MapWeekNumber(weekNumberEnum: Office.MailboxEnums.WeekNumber): number {
    let olWeekNumberTypeEnum = Office.MailboxEnums.WeekNumber;
    let weekNumber = 1;
    switch (weekNumberEnum) {
      case olWeekNumberTypeEnum.Second:
        weekNumber = 2;
        break;
      case olWeekNumberTypeEnum.Third:
        weekNumber = 3;
        break;
      case olWeekNumberTypeEnum.Fourth:
        weekNumber = 4;
        break;
      case olWeekNumberTypeEnum.First:
      case olWeekNumberTypeEnum.Last:
      default:
        weekNumber = 1;
        break;
    }
    return weekNumber;
  }
}
